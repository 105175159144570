<template>
  <b-sidebar
      id="add-new-user-sidebar"
      :visible="isModalSidebarActive"
      backdrop
      bg-variant="white"
      no-header
      right
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-modal-sidebar-active', val)"
      @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
            v-if="isUpdateForm"
            class="mb-0"
        >
          {{ $i18n.t('Edit User') }}
        </h5>
        <h5
            v-else
            class="mb-0"
        >
          {{ $i18n.t('Add User') }}
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          ref="refFormObserver"
          #default="{ handleSubmit }"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(submitForm)"
            @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
              #default="{errors}"
              name="name"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Name')"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="userData.name"
                  :state="errors.length > 0 ? false:null"
                  autofocus
                  placeholder="Name"
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
          >
            <b-form-group
                :label="$i18n.t('Email')"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="errors.length > 0 ? false:null"
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <b-form-group
              :label="$i18n.t('Password')"
              label-for="password"
          >
            <validation-provider
                #default="{ errors }"
                :rules="passwordRules"
                name="password"
                vid="password"
            >
              <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  class="input-group-merge"
              >
                <b-form-input
                    id="password"
                    v-model="userData.password"
                    :state="errors.length > 0 ? false:null"
                    :type="password1FieldType"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="password1ToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirm password -->
          <b-form-group
              :label="$i18n.t('Confirm Password')"
              label-for="password-confirm"
          >
            <validation-provider
                #default="{ errors }"
                :rules="confirmsPasswordRules"
                name="password-confirm"
            >
              <b-input-group
                  :class="errors.length > 0 ? 'is-invalid':null"
                  class="input-group-merge"
              >
                <b-form-input
                    id="password-confirm"
                    v-model="userData.cPassword"
                    :state="errors.length > 0 ? false:null"
                    :type="password2FieldType"
                    class="form-control-merge"
                    name="password-confirm"
                    placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="password2ToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword2Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- User Role -->
          <validation-provider
              #default="{ errors }"
              name="role"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Role')"
                label-for="user-role"
            >
              <v-select
                  v-model="userData.role"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roles"
                  :reduce="val => val.value"
                  input-id="role"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- User Language -->
          <validation-provider
              #default="{ errors }"
              name="lang"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Lang')"
                label-for="user-lang"
            >
              <v-select
                  v-model="userData.lang"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="available_locales"
                  :reduce="val => val"
                  input-id="lang"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-2"
                type="submit"
                variant="primary"
            >
              <span v-if="!isUpdateForm">{{ $i18n.t('Add') }}</span>
              <span v-else>{{ $i18n.t('Update') }}</span>
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              {{ $i18n.t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>

import {alphaNum, email, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  emits: ['is-modal-sidebar-active', 'refetch-data'],
  directives: {
    Ripple,
  },
  model: {
    prop: 'isModalSidebarActive',
    event: 'update:is-modal-sidebar-active',
  },
  props: {
    isModalSidebarActive: {
      type: Boolean,
      required: true,
    },
    isUpdateForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    oldUserData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      roles: [],
      userData: {},
      blankUserData: {
        id: '',
        name: '',
        email: '',
        password: '',
        cPassword: '',
        role: '',
        lang: '',
      },
      available_locales: [],
      repository: RepositoryFactory.create('users'),
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordRules() {
      if (!this.isUpdateForm) {
        return 'required|password'
      }
      return 'password'
    },
    confirmsPasswordRules() {
      if (!this.isUpdateForm) {
        return 'required|confirmed:password'
      }
      return 'confirmed:password'
    }
  },
  watch: {
    oldUserData() {
      this.userData = JSON.parse(JSON.stringify(this.oldUserData))
      this.userData.role = this.userData.role_id
      this.userData.password = ''
      this.userData.cPassword = ''
    }
  },
  mounted() {
    this.resetForm()
    this.getUtils()
  },
  methods: {
    getUtils() {
      this.utilsRepository().addEditUserUtils().then(res => {
        this.roles = res.data.data.roles
        this.available_locales = res.data.data.available_locales
      })
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    resetForm() {
      this.userData = JSON.parse(JSON.stringify(this.blankUserData))
      this.$refs.refFormObserver.reset()
      this.password1FieldType = 'password'
      this.password2FieldType = 'password'
    },
    submitForm() {
      if (!this.isUpdateForm) {
        this.addUser()
      } else {
        this.updateUser()
      }
    },
    addUser() {
      const data = {
        name: this.userData.name,
        email: this.userData.email,
        password: this.userData.password,
        password_confirmation: this.userData.cPassword,
        role: this.userData.role,
        lang: this.userData.lang
      }
      this.repository.store(data).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    updateUser() {
      const data = {
        name: this.userData.name,
        email: this.userData.email,
        role: this.userData.role,
        lang: this.userData.lang
      }
      if (this.userData.password && this.userData.password !== '') {
        this.$set(data, 'password', this.userData.password)
        this.$set(data, 'password_confirmation', this.userData.cPassword)
      }
      this.repository.update(this.userData.id, data).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    successCallback(response) {
      this.$emit('refetch-data')
      this.$emit('update:is-modal-sidebar-active', false)
      this.$emit('update:is-update-form', false)
      this.resetForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: response.data.message
        },
      })
    },
    errorCallback(error) {
      if (error.data && error.data.errors) {
        this.$refs.refFormObserver.setErrors(error.data.errors)
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
